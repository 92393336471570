*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 540px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 720px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1140px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1320px) {
  .container {
    max-width: 1320px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.right-0 {
  right: 0;
}

.top-14 {
  top: 3.5rem;
}

.left-0 {
  left: 0;
}

.bottom-24 {
  bottom: 6rem;
}

.top-0 {
  top: 0;
}

.right-4 {
  right: 1rem;
}

.top-1\/2 {
  top: 50%;
}

.top-full {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.top-5 {
  top: 1.25rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.-top-1 {
  top: -.25rem;
}

.top-4 {
  top: 1rem;
}

.-left-1 {
  left: -.25rem;
}

.-right-1 {
  right: -.25rem;
}

.top-6 {
  top: 1.5rem;
}

.right-16 {
  right: 4rem;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.left-1 {
  left: .25rem;
}

.top-1 {
  top: .25rem;
}

.left-4 {
  left: 1rem;
}

.-bottom-3\.5 {
  bottom: -.875rem;
}

.-bottom-3 {
  bottom: -.75rem;
}

.z-10 {
  z-index: 10;
}

.z-\[-1\] {
  z-index: -1;
}

.z-40 {
  z-index: 40;
}

.z-30 {
  z-index: 30;
}

.z-20 {
  z-index: 20;
}

.m-0 {
  margin: 0;
}

.mx-\[-16px\] {
  margin-left: -16px;
  margin-right: -16px;
}

.my-\[6px\] {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-\[-12px\] {
  margin-left: -12px;
  margin-right: -12px;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-1\.5 {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-auto {
  margin-bottom: auto;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-\[36px\] {
  margin-bottom: 36px;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-\[50px\] {
  margin-bottom: 50px;
}

.mb-\[60px\] {
  margin-bottom: 60px;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.-mt-3\.5 {
  margin-top: -.875rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-0 {
  margin-top: 0;
}

.ml-10 {
  margin-left: 2.5rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.mb-3\.5 {
  margin-bottom: .875rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[436px\] {
  height: 436px;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-12 {
  height: 3rem;
}

.h-auto {
  height: auto;
}

.h-0 {
  height: 0;
}

.h-\[80px\] {
  height: 80px;
}

.h-16 {
  height: 4rem;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[30px\] {
  height: 30px;
}

.h-32 {
  height: 8rem;
}

.h-40 {
  height: 10rem;
}

.h-20 {
  height: 5rem;
}

.h-\[220px\] {
  height: 220px;
}

.h-5 {
  height: 1.25rem;
}

.h-14 {
  height: 3.5rem;
}

.max-h-\[350px\] {
  max-height: 350px;
}

.max-h-10 {
  max-height: 2.5rem;
}

.min-h-0 {
  min-height: 0;
}

.min-h-\[46px\] {
  min-height: 46px;
}

.w-full {
  width: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-\[30px\] {
  width: 30px;
}

.w-4 {
  width: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-12 {
  width: 3rem;
}

.w-\[80px\] {
  width: 80px;
}

.w-16 {
  width: 4rem;
}

.w-32 {
  width: 8rem;
}

.w-40 {
  width: 10rem;
}

.w-20 {
  width: 5rem;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-7 {
  width: 1.75rem;
}

.w-24 {
  width: 6rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-5 {
  width: 1.25rem;
}

.w-0 {
  width: 0;
}

.w-14 {
  width: 3.5rem;
}

.min-w-\[300px\] {
  min-width: 300px;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-0 {
  min-width: 0;
}

.max-w-\[360px\] {
  max-width: 360px;
}

.max-w-\[250px\] {
  max-width: 250px;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-\[620px\] {
  max-width: 620px;
}

.max-w-\[770px\] {
  max-width: 770px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-\[470px\] {
  max-width: 470px;
}

.max-w-\[570px\] {
  max-width: 570px;
}

.max-w-\[18px\] {
  max-width: 18px;
}

.max-w-\[655px\] {
  max-width: 655px;
}

.max-w-\[260px\] {
  max-width: 260px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[75\%\] {
  max-width: 75%;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-default {
  cursor: default;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-8 {
  gap: 2rem;
}

.self-end {
  align-self: flex-end;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-b-md {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-b-3xl {
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-indigo-300\/60 {
  border-color: #a5b4fc99;
}

.border-transparent {
  border-color: #0000;
}

.border-white\/20 {
  border-color: #fff3;
}

.border-body-color {
  --tw-border-opacity: 1;
  border-color: rgb(149 156 177 / var(--tw-border-opacity));
}

.border-gray-700\/30 {
  border-color: #3741514d;
}

.border-red-600\/20 {
  border-color: #dc262633;
}

.border-gray-700\/50 {
  border-color: #37415180;
}

.border-gray-400\/10 {
  border-color: #9ca3af1a;
}

.border-gray-700\/80 {
  border-color: #374151cc;
}

.border-gray-200\/20 {
  border-color: #e5e7eb33;
}

.border-red-700\/60 {
  border-color: #b91c1c99;
}

.border-gray-700\/60 {
  border-color: #37415199;
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.border-indigo-400\/80 {
  border-color: #818cf8cc;
}

.border-opacity-10 {
  --tw-border-opacity: .1;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(74 108 247 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(29 33 68 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(9 14 52 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-gray-700\/60 {
  background-color: #37415199;
}

.bg-\[\#242B51\] {
  --tw-bg-opacity: 1;
  background-color: rgb(36 43 81 / var(--tw-bg-opacity));
}

.bg-\[\#242B51\]\/50 {
  background-color: #242b5180;
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.bg-gray-700\/40 {
  background-color: #37415166;
}

.bg-blue-800\/5 {
  background-color: #1e40af0d;
}

.bg-\[\#1f2b3b\] {
  --tw-bg-opacity: 1;
  background-color: rgb(31 43 59 / var(--tw-bg-opacity));
}

.bg-red-600\/40 {
  background-color: #dc262666;
}

.bg-\[\#101d2c\] {
  --tw-bg-opacity: 1;
  background-color: rgb(16 29 44 / var(--tw-bg-opacity));
}

.bg-gray-800\/40 {
  background-color: #1f293766;
}

.bg-sky-600\/50 {
  background-color: #0284c780;
}

.bg-red-600\/30 {
  background-color: #dc26264d;
}

.bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

.bg-sky-800\/50 {
  background-color: #07598580;
}

.bg-green-900\/20 {
  background-color: #14532d33;
}

.bg-red-700\/20 {
  background-color: #b91c1c33;
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-800\/80 {
  background-color: #1f2937cc;
}

.bg-red-800\/40 {
  background-color: #991b1b66;
}

.bg-gray-700\/80 {
  background-color: #374151cc;
}

.bg-gray-900\/40 {
  background-color: #11182766;
}

.bg-gray-800\/60 {
  background-color: #1f293799;
}

.bg-sky-500\/40 {
  background-color: #0ea5e966;
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-\[3\%\] {
  --tw-bg-opacity: 3%;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-indigo-300\/90 {
  --tw-gradient-from: #a5b4fce6;
  --tw-gradient-to: #a5b4fc00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-blue-500\/90 {
  --tw-gradient-to: #3b82f6e6;
}

.fill-gray-600 {
  fill: #4b5563;
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-left {
  object-position: left;
}

.p-11 {
  padding: 2.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-3 {
  padding: .75rem;
}

.p-2 {
  padding: .5rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-1 {
  padding: .25rem;
}

.p-\[6px\] {
  padding: 6px;
}

.p-2\.5 {
  padding: .625rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-\[15px\] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-\[50px\] {
  padding-top: 50px;
  padding-bottom: 50px;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.pt-\[50px\] {
  padding-top: 50px;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-\[160px\] {
  padding-top: 160px;
}

.pb-\[80px\] {
  padding-bottom: 80px;
}

.pt-\[60px\] {
  padding-top: 60px;
}

.pb-\[30px\] {
  padding-bottom: 30px;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-\[100px\] {
  padding-top: 100px;
}

.pb-\[120px\] {
  padding-bottom: 120px;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pl-36 {
  padding-left: 9rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-tight {
  line-height: 1.25;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.text-body-color {
  --tw-text-opacity: 1;
  color: rgb(149 156 177 / var(--tw-text-opacity));
}

.text-\[\#CED3F6\] {
  --tw-text-opacity: 1;
  color: rgb(206 211 246 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(9 14 52 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(29 33 68 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray-300\/60 {
  color: #d1d5db99;
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400\/80 {
  color: #9ca3afcc;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-300\/90 {
  color: #d1d5dbe6;
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(74 108 247 / var(--tw-text-opacity));
}

.text-blue-100\/50 {
  color: #dbeafe80;
}

.text-white\/70 {
  color: #ffffffb3;
}

.text-gray-200\/80 {
  color: #e5e7ebcc;
}

.text-gray-300\/70 {
  color: #d1d5dbb3;
}

.text-red-400\/90 {
  color: #f87171e6;
}

.text-red-200\/70 {
  color: #fecacab3;
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-red-500\/70 {
  color: #ef4444b3;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-red-500\/80 {
  color: #ef4444cc;
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.opacity-70 {
  opacity: .7;
}

.opacity-60 {
  opacity: .6;
}

.opacity-40 {
  opacity: .4;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-one {
  --tw-shadow: 0px 2px 3px #07074d0d;
  --tw-shadow-colored: 0px 2px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-signUp {
  --tw-shadow: 0px 5px 10px #040a2233;
  --tw-shadow-colored: 0px 5px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline-0 {
  outline-width: 0;
}

.ring-primary {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(74 108 247 / var(--tw-ring-opacity));
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.faq-link {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(147 197 253 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
  margin-left: .25rem;
  margin-right: .25rem;
}

.faq-link:hover {
  border-bottom-width: 1px;
}

.options-list-section-header {
  display: none;
}

.address-limit {
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dashboard-icon {
  vertical-align: text-top;
  opacity: .6;
  margin-right: .25rem;
  display: inline;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
  gap: 0 !important;
}

.hover\:border-indigo-300\/30:hover {
  border-color: #a5b4fc4d;
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#242B51\]\/70:hover {
  background-color: #242b51b3;
}

.hover\:bg-gray-700\/60:hover {
  background-color: #37415199;
}

.hover\:bg-gray-800\/80:hover {
  background-color: #1f2937cc;
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: .2;
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: .9;
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(74 108 247 / var(--tw-text-opacity));
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.hover\:text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.hover\:text-red-600\/80:hover {
  color: #dc2626cc;
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-70:hover {
  opacity: .7;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:opacity-90:hover {
  opacity: .9;
}

.hover\:opacity-60:hover {
  opacity: .6;
}

.hover\:shadow-signUp:hover {
  --tw-shadow: 0px 5px 10px #040a2233;
  --tw-shadow-colored: 0px 5px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:grayscale-0:hover {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(74 108 247 / var(--tw-border-opacity));
}

.focus\:border-\[\#1976d2\]:focus {
  --tw-border-opacity: 1;
  border-color: rgb(25 118 210 / var(--tw-border-opacity));
}

.focus\:border-gray-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.focus\:border-gray-700\/80:focus {
  border-color: #374151cc;
}

.focus\:border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.focus\:bg-\[\#171c3a\]:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(23 28 58 / var(--tw-bg-opacity));
}

.focus\:bg-gray-900\/80:focus {
  background-color: #111827cc;
}

.focus\:bg-gray-900\/60:focus {
  background-color: #11182799;
}

.focus\:bg-gray-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.focus\:shadow-md:focus {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:text-white\/80 {
  color: #fffc;
}

.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-70 {
  opacity: .7;
}

.group:hover .group-hover\:opacity-80 {
  opacity: .8;
}

@media (prefers-color-scheme: dark) {
  .dark\:border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
  }

  .dark\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .dark\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .dark\:bg-dark {
    --tw-bg-opacity: 1;
    background-color: rgb(29 33 68 / var(--tw-bg-opacity));
  }

  .dark\:bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(74 108 247 / var(--tw-bg-opacity));
  }

  .dark\:bg-\[\#242B51\] {
    --tw-bg-opacity: 1;
    background-color: rgb(36 43 81 / var(--tw-bg-opacity));
  }

  .dark\:bg-\[\#1D2144\] {
    --tw-bg-opacity: 1;
    background-color: rgb(29 33 68 / var(--tw-bg-opacity));
  }

  .dark\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .dark\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
  }

  .dark\:opacity-80 {
    opacity: .8;
  }

  .dark\:opacity-90 {
    opacity: .9;
  }

  .dark\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: .2;
  }

  .dark\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
  }

  .dark\:hover\:opacity-100:hover {
    opacity: 1;
  }
}

@media (min-width: 540px) {
  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:p-\[55px\] {
    padding: 55px;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:leading-tight {
    line-height: 1.25;
  }

  .sm\:leading-relaxed {
    line-height: 1.625;
  }
}

@media (min-width: 720px) {
  .md\:visible {
    visibility: visible;
  }

  .md\:relative {
    position: relative;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mb-\[70px\] {
    margin-bottom: 70px;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:mb-\[90px\] {
    margin-bottom: 90px;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mb-2 {
    margin-bottom: .5rem;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-16 {
    height: 4rem;
  }

  .md\:h-40 {
    height: 10rem;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:h-7 {
    height: 1.75rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:w-7 {
    width: 1.75rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:w-16 {
    width: 4rem;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:max-w-\[75\%\] {
    max-width: 75%;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:border-r {
    border-right-width: 1px;
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:py-\[100px\] {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .md\:py-\[40px\] {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .md\:px-\[50px\] {
    padding-left: 50px;
    padding-right: 50px;
  }

  .md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:pt-\[100px\] {
    padding-top: 100px;
  }

  .md\:pt-\[150px\] {
    padding-top: 150px;
  }

  .md\:pb-\[120px\] {
    padding-bottom: 120px;
  }

  .md\:pb-\[50px\] {
    padding-bottom: 50px;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-\[45px\] {
    font-size: 45px;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:leading-tight {
    line-height: 1.25;
  }

  .md\:leading-relaxed {
    line-height: 1.625;
  }
}

@media (min-width: 960px) {
  .lg\:static {
    position: static;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-4\/12 {
    width: 33.3333%;
  }

  .lg\:w-2\/12 {
    width: 16.6667%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-2\/3, .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-\[130px\] {
    max-width: 130px;
  }

  .lg\:max-w-\[1600px\] {
    max-width: 1600px;
  }

  .lg\:max-w-sm {
    max-width: 24rem;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:bg-transparent {
    background-color: #0000;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pr-16 {
    padding-right: 4rem;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  @media (prefers-color-scheme: dark) {
    .lg\:dark\:bg-transparent {
      background-color: #0000;
    }
  }
}

@media (min-width: 1140px) {
  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:w-5\/12 {
    width: 41.6667%;
  }

  .xl\:w-2\/12 {
    width: 16.6667%;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:max-w-\[150px\] {
    max-width: 150px;
  }

  .xl\:max-w-xl {
    max-width: 36rem;
  }

  .xl\:object-contain {
    object-fit: contain;
  }

  .xl\:p-\[50px\] {
    padding: 50px;
  }

  .xl\:p-\[55px\] {
    padding: 55px;
  }

  .xl\:p-11 {
    padding: 2.75rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:pt-\[180px\] {
    padding-top: 180px;
  }

  .xl\:pb-\[160px\] {
    padding-bottom: 160px;
  }

  .xl\:text-\[45px\] {
    font-size: 45px;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 1320px) {
  .\32 xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .\32 xl\:ml-16 {
    margin-left: 4rem;
  }

  .\32 xl\:max-w-\[160px\] {
    max-width: 160px;
  }

  .\32 xl\:max-w-2xl {
    max-width: 42rem;
  }

  .\32 xl\:p-8 {
    padding: 2rem;
  }

  .\32 xl\:py-\[60px\] {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .\32 xl\:px-\[70px\] {
    padding-left: 70px;
    padding-right: 70px;
  }

  .\32 xl\:pt-\[210px\] {
    padding-top: 210px;
  }

  .\32 xl\:pb-\[200px\] {
    padding-bottom: 200px;
  }
}

/*# sourceMappingURL=index.794f4329.css.map */
