@tailwind base;
@tailwind components;
@tailwind utilities;

.faq-link {
  @apply mx-1 text-blue-300 hover:border-b border-b-blue-300;
}

.options-list-section-header {
  display: none;
}

.address-limit {
  @apply whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[180px];
}

.dashboard-icon {
  @apply inline mr-1 align-text-top opacity-60;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
  gap: 0 !important;
}